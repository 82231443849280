@font-face {
  font-family: 'SegoeUI-Regular';
  src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.eot');
  src: url('../fonts/SegoeUI-Regular/SegoeUI-Regular.svg') format('svg'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.woff') format('woff'), url('../fonts/SegoeUI-Regular/SegoeUI-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI-Semibold';
  src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.eot');
  src: url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.svg') format('svg'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.woff') format('woff'), url('../fonts/SegoeUI-Semibold/SegoeUI-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SegoeUI-Bold';
  src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.eot');
  src: url('../fonts/SegoeUI-Bold/SegoeUI-Bold.svg') format('svg'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.woff') format('woff'), url('../fonts/SegoeUI-Bold/SegoeUI-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  color: #262626 !important;
  font-family: 'SegoeUI-Regular' !important;
  font-size: 14px !important;
}

a, div, span, h4, .icon {
  transition: all 0.2s ease-in-out !important;
}

.float-right {
  float: right !important;
}

.float-left {
  float: left !important;
}
.cursorPointer{
  cursor: pointer;
}
#root, .template {
  height: 100%;
}


.containerMain {
  padding: 20px;
  overflow: auto;
  height: calc(100% - 60px);
}

video {
  width: 100%;
}

.text-center {
  text-align: center;
}

.cursorPointer {
  cursor: pointer;
}

i.icon {
  color: #96989d !important;
}
.mt10{
  margin-top: 10px !important;
}
.mr10 {
  margin-right: 10px !important;
}
.ml10{
  margin-left: 10px !important;
}
.ml20{
  margin-left: 20px !important;
}
.mr20 {
  margin-right: 20px !important;
}
.mr0{
  margin-right: 0 !important;
}
.mb5{
  margin-bottom: 5px !important;
}
.mb10 {
  margin-bottom: 10px !important;
}

.menuPic {
  height: 40px;
  width: 40px;
  border-radius: 50px;
  margin: 5px;
}

.ui.grid > .row > .column.customPadding {
  padding: 0 5px !important;
}
a{
  color: #007bff !important;
}
.red-text{
  color: #e90000 !important;
}
i.icon.red-text{
  color: #e90000 !important;
}

.red-backgroud{
  background-color: #e90000 !important;
}
.green-backgroud{
  background-color: #49b144 !important;
}
span.red-backgroud, span.green-backgroud {
  width: 50px;
  height: 20px;
  display: inline-block;
}
/*--------------------------------------------SignIn-------------------------------***/
.signin {
  height: 100%;
  width: 100%;
  float: left;
  background: url("../images/bgShapeBottom.png") no-repeat #fbfbfb;
  background-position: 0 100%;
  background-size: 42%;
}

.bgImage {
  height: 100%;
  width: 100%;
  background: url("../images/bgShapeTop.png") no-repeat;
  background-position: 100% 0;
  background-size: 29%;
}

.signin .ui.grid {
  height: 100%;
}

/*.logoSignIn {*/
/*  margin-top: 16%;*/
/*}*/

.signin .google {
  padding: 5px 20px !important;
  margin-top: 40px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.15) !important;
  border-radius: 5px !important;
}

.signin .google span {
  font-size: 18px;
}

/*--------------------------------------------Heading-------------------------------***/
h2, h3, h4 {
  font-family: 'SegoeUI-Semibold' !important;
  font-weight: normal !important;
  color: #000 !important;
}

h2 {
  font-size: 20px !important;
  margin: 4px 0 10px 0 !important;
}

h3 {
  font-size: 16px !important;
  text-transform: uppercase;
  margin-bottom: 10px !important;
}
h4 {
  font-size: 15px !important;
  margin-bottom: 10px !important;
}

/*--------------------------------------------Header-------------------------------*/
.template .ui.basic.segment > .ui.secondary.menu {
  background: #fff;
  height: 60px;
  color: #273140;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  margin: 0;
  width: 100%;
}
.ui.secondary.menu .item{
  margin-right: 0 !important;
  padding: 0 !important;
}
.ui.secondary.menu .item.setting{
  padding: 23px !important;
  margin-left: 10px !important;
}
.ui.secondary.menu .item.setting .icon{
  margin-right: 0 !important;
}
.ui.secondary.menu .item.setting .menu{
  width: 150px;
  margin-right: 5px;
  border: none !important;
}
.ui.menu .ui.dropdown.setting .menu > .item .icon:not(.dropdown){
  margin-right: 0 !important;
}

/*--------------------------------------------Button-------------------------------***/
.ui.button {
  color: #fff !important;
  font-weight: normal !important;
  padding: 12px 30px !important;
  box-shadow: none !important;
}

.ui.button.small {
  padding: 8px 20px !important;
  font-size: 14px !important;
}

.ui.button.primary {
  background: #156b83 !important;
}

.ui.button.primary:hover {
  background: #136075 !important;
}


/*-------------------------------------------SideBar-------------------------------*/


/*-------------------------------------------SideBar-------------------------------*/
.template .ui.segment {
  border-radius: 0;
  border: none;
  box-shadow: none;
  padding: 0 66px 0 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.template .ui.segment.pushable {
  float: left;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.template .ui.thin.left.sidebar.visible {
  width: 300px;
  background: #d48234;
}

.template .ui.visible.thin.left.sidebar ~ .fixed, .template .ui.visible.thin.left.sidebar ~ .pusher {
  transform: translate3d(300px, 0px, 0px);
}

.template .ui.thin.left.sidebar ~ .fixed, .template .ui.thin.left.sidebar ~ .pusher {
  transform: translate3d(66px, 0px, 0px);
}

.template .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
  padding-right: 300px;
  transition: all 0.66s ease 0s;
  -webkit-transition: all 0.66s ease 0s;
}

.template .ui.thin.left.sidebar ~ .pusher  .btnFeedback{
  right: 60px;
}

.template .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment .btnFeedback{
right:60px;
}

.template .pushable > .pusher {
  background: #e7eff1;
}

.template .ui.thin.left.sidebar {
  width: 66px;
  background: #d48234;
  overflow-x: hidden;
}

.template .ui.left.push.sidebar {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  visibility: visible;
}

.template .ui.thin.left.sidebar::-webkit-scrollbar {
  display: none;
}

.template .ui.visible.push.sidebar {
  -webkit-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}

.template .ui.thin.left.sidebar .logoHolder {
  height: 90px;
  padding: 16px 0 0 7px;
  background: #ffffff;
  text-align: left;
  margin-top: 60px;
  margin-left: 5px;
  border-radius: 45px 0 0 45px;
}

.template .ui.thin.left.sidebar .ui.accordion.leftMenu {
  margin: 50px 0 0 10px;
}

.template .ui.thin.left.sidebar .toggleBtn .icon {
  margin: 15px;
  color: #fff !important;
}

.template .ui.thin.left.sidebar.visible .toggleBtn .icon {
  margin: 15px;
  color: #fff !important;
}

.template .leftMenu.ui.accordion > a {
  font-size: 0;
  text-align: center;
  padding: 15px 15px 20px 15px;
}

/*.logoHolder {*/
/*  padding: 20px 10px 0 30px;*/
/*  width: 100%;*/
/*  height: 156px;*/
/*  text-align: left;*/
/*}*/

.toggleBtn {
  width: 100%;
}

.toggleBtn .icon {
  font-size: 22px;
  float: right;
  cursor: pointer;
}

.template .ui.thin.left.sidebar.visible .logoHolder {
  padding: 16px 0 0 20px;
  width: 275px;
  height: 90px;
  background: #ffffff;
  text-align: left;
  margin-top: 60px;
  margin-left: 25px;
  border-radius: 45px 0 0 45px;
}

/*.toggleBtn {*/
/*  width: 100%;*/
/*}*/

/*.toggleBtn .icon {*/
/*  font-size: 22px;*/
/*  color: #fff !important;*/
/*  float: right;*/
/*  margin: 15px;*/
/*  cursor: pointer;*/
/*}*/

/*------------------------------------LeftMenu-----------------------------------*/

.ui.accordion.leftMenu {
  margin: 50px 0 0 25px;
}

.ui.accordion.leftMenu .title {
  text-align: left;
  padding: 10px 15px 10px 20px !important;
  position: relative;
  border-radius: 20px 0 0 20px;
  margin-bottom: 10px;
  float: left;
  width: 100%;
}

.ui.accordion.leftMenu .content {
  float: left;
  width: 100%;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ui.accordion.leftMenu > .title.active,
.ui.accordion.leftMenu .content.active {
  background: #fff;
}

.ui.accordion.leftMenu .title.active a,
.ui.accordion.leftMenu .title.active a .icon {
  color: #d48234 !important;
}

.ui.accordion.leftMenu .title a .icon {
  margin-right: 15px !important;
  color: #f3ded1 !important;
}

.ui.accordion.leftMenu .title a {
  color: #f3ded1 !important;
  width: 100% !important;
  float: left;
}

.template .leftMenu.ui.accordion a {
  font-size: 0;
}

.template .leftMenu.ui.accordion a .icon {
  float: left;
  margin-right: 0 !important;
}

.template .leftMenu.ui.accordion a .icon.angle {
  font-size: 12px;
}

.template .leftMenu.ui.accordion a .icon,
.template .ui.sidebar.visible .leftMenu.ui.accordion a,
.template .ui.sidebar.visible .ui.accordion.leftMenu .title a .icon.angle {
  font-size: 15px;
}

.template .ui.sidebar.visible .ui.accordion.leftMenu .ui.list a {
  font-size: 14px !important;
  padding: 14px 15px !important;
  text-align: left;
}

.ui.accordion.leftMenu .ui.list a {
  font-size: 0 !important;
  padding: 14px 15px !important;
  text-align: center;
}

.template .ui.sidebar.visible .ui.accordion.leftMenu .ui.list a .icon {
  float: left;
}

.ui.accordion.leftMenu .ui.list a .icon {
  float: none;
}

.template .ui.sidebar.visible .ui.accordion.leftMenu .title a .icon {
  margin-right: 15px !important;
}

.ui.accordion.leftMenu .title a .icon {
  color: #fff !important;
}

.ui.accordion.leftMenu .title a .icon.angle {
  float: right;
  margin-right: 0 !important;
}

.ui.accordion.leftMenu .title.active a .icon.angle {
  transform: rotate(180deg);
}


/* ------------------------------------------------*/


/*---------------------------------------Card--------------------------------------*/
.ui.card {
  width: 100% !important;
  box-shadow: none !important;
  margin-bottom: 20px !important;
  margin-top: 0 !important;
  border-radius: 4px !important;
}

.ui.card .content {
  padding: 15px !important;
}

.ui.card.pageTitle .content {
  padding: 10px 15px !important;
}

.ui.card.pageTitle .content.pb0 {
  padding: 10px 15px 0 15px !important;
}
/*---------------------------------------Top-Filters--------------------------------------*/
.topFilters{
  text-align: right;
  justify-content: right;
  float: right;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

}

/*---------------------------------------Alert--------------------------------------*/
.alerts .customGrid {
  width: 20% !important;
  float: left;
  padding: 0 10px !important;
}

.ui.card.alertCard .content:first-child {
  height: 300px !important;
}

.ui.card.alertCard label {
  color: #96989d;
  font-size: 13px;
  text-transform: uppercase;
}

.ui.card.alertCard p {
  margin-bottom: 10px !important;
}

.ui.card.alertCard p:last-child {
  margin-bottom: 0 !important;
}

.ui.card.alertCard .content.extra {
  padding: 0 15px !important;
  border-top: 1px solid #e7eff1 !important;
}

.ui.card.alertCard .content.extra .icon {
  margin: 8px 0 12px 0;
}

.ui.card.alertCard .content.extra .icon:hover {
  opacity: 0.7;
}

.ui.card.alertCard .content.extra .icon:nth-child(2) {
  border-left: 1px solid #e7eff1;
  border-right: 1px solid #e7eff1;
}

.ui.card.alertCard .content.extra .column:nth-child(2) {
  border-left: 1px solid #e7eff1;
  border-right: 1px solid #e7eff1;
}

.ui.card.alertCard.borderRed {
  box-shadow: 0px -5px 0px 0px #e90000 !important;
}

.ui.card.alertCard.borderGreen {
  box-shadow: 0px -5px 0px 0px #49b144 !important;
}

.ui.card.alertCard .alertText label {
  display: block;
  line-height: 10px !important;
}

.ui.styled.accordion {
  width: 100% !important;
}

/*---------------------------------------Table--------------------------------------*/
.ui.table.customTable {
  border: none;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.ui.table.customTable tr td {
  border-bottom: 1px solid #e9e9e9;
  border-top: none !important;
}

.ui.table.customTable tr:first-child td {
  border-top: 1px solid #868686 !important;
}

.ui.table.customTable thead th {
  font-family: 'SegoeUI-Semibold' !important;
  font-weight: normal !important;
  background: #fff;
  color: #156b83;
  border-bottom: 3px solid transparent;
  border-radius: 0 !important;
  vertical-align: middle !important;
  padding: 2px 0.75rem;
  white-space: nowrap;
}

.ui.table.customTable thead th > div {
  min-height: 43px;
  align-items: center;
  display: flex;
  align-content: center;
}

.ui.table.customTable thead th.active {
  border-bottom: 3px solid #156b83 !important;
}

.ui.table.customTable thead i.icon {
  color: #156b83 !important;
  opacity: 0.4;
  margin-left: 10px;
}

.ui.table.customTable thead th.active i.icon {
  opacity: 1;
}

.ui.table.customTable thead th:hover i.icon {
  color: #156b83 !important;
  opacity: 1;
}
i.icon.edit:hover,
i.icon.trash:hover{
  color: #656565 !important;
}

/*------------------------------FreezeTable----------------------*/
.FreezeTable {
  max-width: 100%;
  max-height: 70vh;
  overflow: scroll;
  position: relative;
}

.FreezeTable table {
  position: relative;
  border-collapse: collapse;
}

.FreezeTable thead th {
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  top: 0;
}
.FreezeTable thead tr:first-child th:first-child{
  z-index: 2;
  left: 0;
}
.FreezeTable thead tr:first-child th:nth-child(2){
  left: 70px;
  z-index: 2;
}

.FreezeTable-first-child {
  left: 0;
  z-index: 1;
}

.FreezeTable tbody td:first-child, .FreezeTable tbody td:nth-child(2)
{
  position: -webkit-sticky; /* for Safari */
  position: sticky;
  background: #fff;
}

.FreezeTable tbody td:first-child{
  left: 0;
  z-index: 1;
}

.FreezeTable tbody td:nth-child(2){
  left: 70px;
  z-index:1;
}
/*---------------------------------------Checkbox--------------------------------------*/
.ui.checkbox input:checked ~ .box:before, .ui.checkbox input:checked ~ label:before {
  border-color: #156b83 !important;
}

.ui.checkbox input:checked ~ .box:after, .ui.checkbox input:checked ~ label:after {
  color: #156b83 !important;;
}
/*------------------------------------------------------Feedback-btn------------------------------------------------*/
.btnFeedback{
  width: 30px;
  height: 100px !important;
  background-color: #d16f26;
  border-radius: 10px 0 0 10px;
  z-index: 999;
  box-shadow: 0px 0px 15px rgba(68, 68, 68, 0.4) !important;
  text-align: center;
  color: #222;
  padding: 18px 0;
  position: fixed;
  right: 1px;
  cursor: pointer;
  top: 50%;
}
.btnFeedback .icon{
  font-size: 20px !important;
  color: #fff !important;
  margin-right: 0 !important;
}
.btnFeedback small{
  font-size: 13px;
  color: #fff;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  letter-spacing: 0.5px;
}
.btnFeedback:hover{
  width: 40px;
  background-color: #c4631b;
}
/*---------------------------------------Modal--------------------------------------*/
.ui.modal > .header{
  font-size: 20px !important;
  border-bottom: none !important;
  color: #262626 !important;
}
.howToFix .videoThumbnail{
  width: 200px;
  float: left;
  margin-right: 20px;
  margin-left: 20px;
  margin-bottom: 20px;
}
/*---------------------------------------Responsive--------------------------------------*/
@media (max-width: 1400px) {
  .alerts .customGrid {
    width: 25% !important;
    font-size: 10px;
  }
}

@media (max-width: 1199px) {
  .template .ui.visible.thin.left.sidebar ~ .fixed, .template .ui.visible.thin.left.sidebar ~ .pusher {
    transform: translate3d(66px, 0px, 0px);
  }

  .template .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment {
    padding-right: 66px;
  }
  .signin {
    background-size: 55%;
  }

  .bgImage {
    background-size: 36%;
  }
  .template .ui.visible.thin.left.sidebar ~ .pusher .ui.basic.segment .btnFeedback {
    right: 0;
}
}

@media (max-width: 991px) {

  .logoSignIn {
    width: 420px;
  }
  .alerts .customGrid {
    width: 33.33% !important;
  }
}

@media (max-width: 767px) {
  .containerMain{
    padding: 15px;
  }
  .signin {
    height: 100vh;
  }
  .alerts .customGrid {
    width: 50% !important;
  }
  h2{
    font-size: 18px !important;
  }
  h3{
    font-size: 15px !important;
  }
  .ui.modal > .header{
    font-size: 17px !important;
  }
  .ui.table.customTable tr td{
    padding: 5px 8px !important;
  }
  .ui.table.customTable tr th{
    padding: 2px 8px !important;
  }
  .template .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback,
  .btnFeedback{
      right: 0;
  }
  .template .ui.visible.thin.left.sidebar ~ .pusher .btnFeedback,
  .btnFeedback{
      right: 0 !important;
  }
 }

@media (max-width: 575px) {
  .logoSignIn {
    width: 380px;
  }
  .alerts .customGrid {
    width: 100% !important;
  }
  .ui.card.alertCard .content:first-child{
    height: auto !important;
  }
  .topFilters {
    width: auto;
    text-align: right;
  }

  .topFilters .ui.dropdown,
  .topFilters .ui.input {
    width: 100%;
    min-width: auto !important;
  }

  .topFilters .ui.button {
    float: right;
    margin-right: 0 !important;
  }

  .topFilters div.float-right {
    text-align: right;
  }
}

@media (max-width: 360px) {
  .logoSignIn {
    width: 320px;
  }
}
.logo
{
  border: none;
  background-image: url(logoMain.png);
  background-repeat: no-repeat;
  background-position: center center;
  width: 192px;
  height: 59px;
  cursor: pointer;
}

.helpMark {
  margin: -15px 20px 0 10px;
  float: right;
}
