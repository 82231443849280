.xray-metadata-viewer{
  margin: 0;
  position: fixed;
  background: rgba(0,0,0,.90);
  color: white;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.6em 0;
  z-index: 1000;
}
